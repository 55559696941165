<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">Ödeme Alma Bilgileri</h3>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Hesap Sahibi</label>
          <input type="text" class="form-control" v-model="accountOwner" />
        </div>
        <div class="form-group">
          <label>Hesap Türü</label>
          <select type="text" v-model="bussinessType" class="form-control">
            <option value="0" disabled>Hesap Türü Seçiniz</option>
            <option value="1">Bireysel</option>
            <option value="2">Şahıs Şirketi</option>
            <option value="3">Limited/Anonim Şirket</option>
          </select>
        </div>
        <div class="form-group">
          <label v-if="bussinessType == 1 || bussinessType == 2"
            >TC Kimlik No</label
          >
          <label v-if="bussinessType == 3">Vergi Kimlik No</label>
          <input
            v-if="bussinessType == 1 || bussinessType == 2"
            type="text"
            class="form-control"
            v-model="ownerIdentity"
          />
          <input
            v-if="bussinessType == 3"
            type="text"
            class="form-control"
            v-model="ownerIdentity"
          />
        </div>
        <div class="form-group" v-if="bussinessType == 3">
          <label>Yetkili Kişi TC Kimlik</label>
          <input type="text" v-model="officalIdentity" class="form-control" />
        </div>

        <div class="form-group">
          <label>Firma Ünvanı</label>
          <input v-model="bussinessTitle" type="text" class="form-control" />
        </div>
        <div class="form-group">
          <label>GSM Numarası</label>
          <input
            v-model="ownerPhone"
            type="text"
            class="form-control"
            v-mask="'0(XXX) XXX XX XX'"
          />
        </div>
        <div class="form-group">
          <label>IBAN numarasına ait Ad-Soyad/Unvan</label>
          <input type="text" v-model="ibanOwner" class="form-control" />
        </div>
        <div class="form-group">
          <label>IBAN</label>
          <input
            type="text"
            v-model="iban"
            class="form-control"
            v-mask="'AA #### #### #### #### #### ####'"
          />
        </div>
        <div class="form-group">
          <label>Adres</label>
          <input type="text" v-model="address" class="form-control" />
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>İl</label>
              <input type="text" v-model="city" class="form-control" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>İlçe</label>
              <input type="text" v-model="region" class="form-control" />
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Vergi Dairesi</label>
          <input type="text" class="form-control" v-model="taxOffice" />
        </div>
        <div class="form-group">
          <input type="checkbox" v-model="selected" id="iban" />
          <label for="iban">&nbsp;Bilgilerin doğruluğunu onaylıyorum</label>
        </div>
        <div class="">
          Not: Bu bilgiler yapılan satışlarda tutarın tarafınıza aktarılması
          aşamasında kullanılacaktır.Lütfen eksiksiz ve doğru bir şekilde
          girdiğinize emin olun!
        </div>
      </div>
      <div class="card-footer" style="padding: 10px">
        <button
          type="button"
          id="saveButton"
          @click="save"
          class="btn btn-primary btn-sm float-right"
        >
          Kaydet
        </button>
      </div>
    </div>
  </div>
</template>
  <style scoped>
.select_band_one {
  border: 1px solid #dddddd;
  background-color: #fff;
  padding: 10px;
  text-align: center;
  border-radius: 5px;

  transition: all cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.active_banner {
  background: rgb(143 74 106 / 22%);
}
.select_band_one:hover {
  cursor: pointer;
  transition: all 0.5s;
}
</style>
  <script>
import Swal from "sweetalert2";

export default {
  created() {
    document.title = "Ödeme Alma Bilgileri";
    this.$store.dispatch("payInfo").then((value) => {
      if (value != null) {
        this.title = value.title;
        this.accountOwner = value.accountOwner;
        this.ownerIdentity = value.ownerIdentity;
        this.bussinessType = value.bussinessType;
        this.bussinessTitle = value.bussinessTitle;
        this.ownerPhone = value.ownerPhone;
        this.birthday = value.birthday;
        this.ibanOwner = value.ibanOwner;
        this.iban = value.iban;
        this.city = value.city;
        this.region = value.region;
        this.officalIdentity = value.officalIdentity;
        this.officalBirthday = value.officalBirthday;
        this.taxOffice = value.taxOffice;
        this.address = value.address;
        this.selected = value.selected;
      }
    });
  },
  data() {
    return {
      selected: 0,
      title: "",
      accountOwner: "",
      ownerIdentity: "",
      bussinessType: 1,
      bussinessTitle: "",
      ownerPhone: "",
      address: "",
      birthday: "",
      ibanOwner: "",
      iban: "",
      city: "",
      region: "",
      officalIdentity: "",
      officalBirthday: "",
      taxOffice: "",
    };
  },
  methods: {
    save() {
      if (this.accountOwner == "" || this.accountOwner == null) {
        this.$vToastify.warning("Hesap sahibi Ad-Soyad girmediniz", "Uyarı!");
      } else if (this.ownerIdentity == "" || this.ownerIdentity == null) {
        this.$vToastify.warning("Hesap sahibi TCKN girmediniz", "Uyarı!");
      } else if (this.bussinessTitle == "" || this.bussinessTitle == null) {
        this.$vToastify.warning("İşletme adı girmediniz", "Uyarı!");
      } else if (this.ownerPhone == "" || this.ownerPhone == null) {
        this.$vToastify.warning(
          "Hesap sahibi GSM numarası girmediniz",
          "Uyarı!"
        );
      } else if (this.ibanOwner == "") {
        this.$vToastify.warning("IBAN sahibi Ad-Soyad girmediniz", "Uyarı!");
      } else if (this.iban == "") {
        this.$vToastify.warning("IBAN girmediniz", "Uyarı!");
      } else if (this.city == "") {
        this.$vToastify.warning("İl girmediniz", "Uyarı!");
      } else if (this.region == "") {
        this.$vToastify.warning("Bölge girmediniz", "Uyarı!");
      } else if (this.officalIdentity == "" && this.bussinessType == 3) {
        this.$vToastify.warning("Kurum yetkilisi TCKN girmediniz", "Uyarı!");
      } else if (this.taxOffice == "") {
        this.$vToastify.warning("Vergi dairesi girmediniz", "Uyarı!");
      } else if (this.address == "") {
        this.$vToastify.warning("Adres girmediniz", "Uyarı!");
      } else if (this.selected == 0) {
        this.$vToastify.warning(
          "Ödeme bilgisi doğruluğunu onaylamadınız",
          "Uyarı!"
        );
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML =
          "Bilgiler kayıt ediliyor..";
        let profileSave = {
          title: this.title,
          accountOwner: this.accountOwner,
          ownerIdentity: this.ownerIdentity,
          bussinessType: this.bussinessType,
          bussinessTitle: this.bussinessTitle,
          ownerPhone: this.ownerPhone,
          birthday: this.birthday,
          ibanOwner: this.ibanOwner,
          iban: this.iban,
          city: this.city,
          region: this.region,
          officalIdentity: this.officalIdentity,
          officalBirthday: this.officalBirthday,
          taxOffice: this.taxOffice,
          address: this.address,
          selected: this.selected,
        };
        this.$store.dispatch("paySave", profileSave).then((value) => {
          document.getElementById("saveButton").disabled = false;
          document.getElementById("saveButton").innerHTML = "Kaydet";
          if (value.type == "success") {
            this.$vToastify.success("Bilgi güncelleme başarılı", "Başarılı!");
          }
          if (value.type == "error") {
            this.$vToastify.error(value.message.errorMessage, "Hata!");
          }
        });
      }
    },
  },
  components: {},
  mounted() {},
};
</script>